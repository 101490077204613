<template>
  <div
    id="cancelBookingReason"
    aria-hidden="true"
    aria-labelledby="staticBackdropLabel"
    class="modal fade"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 id="CancelBookingLabel" class="modal-title">
            <small>Cancel Booking</small>
          </h6>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">Tell us why and help us improve</div>
        <div class="modal-body">
          <div class="mb-3" v-for="(reason, index) in reasonsList" :key="index">
            <input
              :id="index"
              class="form-check-input me-2"
              name="cancelReason"
              type="radio"
              @click="getCancelBookingReason(reason)"
            />
            <label class="form-check-label verificationLabel" :for="index">
              {{ reason.actor }}
            </label>
          </div>
          <!-- 
          <div class="mb-3">
            <input
              id="10"
              class="form-check-input me-2"
              name="cancelReason"
              type="radio"
              @change="displayOtherReasonTextBox($event)"
            />
            <label class="form-check-label verificationLabel" for="other">
              Other
            </label>
          </div> -->

          <div :class="otherReason ? 'mb-3 d-block' : 'mb-3 d-none'">
            <textarea
              id="otherReason"
              class="form-control me-2 rounded-3"
              name="otherReason"
              placeholder="Why do you want to cancel the booking?"
              v-model="passengerCancelReason"
            />
          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center modal-footer"
        >
          <button class="btn btnSubmit" @click="submitCancel">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vuex from "vuex";
import BookingService from "../../../services/BookingService";
import VueSweetalert2 from "sweetalert2";
export default {
  name: "CancelBookingReason",
  data() {
    return {
      otherReason: false,
      passengerCancelReason: "",
      reasonID: 0,
      reasonsList: [],
    };
  },
  computed: {
    ...vuex.mapGetters(["getBookingId"]),
  },

  mounted() {
    // axios
    //   .post(
    //     'http://login.emerge.lk:9090/MyPorterAPI/api/Validation/GetReasons?reasonId=1'
    //   )
    //   .then((response) => (this.reasonsList = response.data));
    this.getList();
  },

  methods: {
    async getList() {
      // const getListItems = await axios.post(
      //   "http://login.emerge.lk:9090/MyPorterAPI/api/Validation/GetReasons?reasonId=1"
      // );
      BookingService.getCancelReasons()
        .then((response) => {
          this.reasonsList = response.data;

          (error) => {};
        })
        .catch((error) => {});
    },
    displayOtherReasonTextBox: function (event) {
      if (this.otherReason) {
        this.otherReason = false;
      } else {
        this.otherReason = true;
        this.reasonID = 1000;
      }
    },

    getCancelBookingReason: function (reason) {
      console.log("reason", reason);
      this.otherReason = false;
      this.reasonID = reason.reasonId != 0 ? reason.reasonId : 100;
      this.passengerCancelReason =
        reason.reasonID == 0 ? reason.reasonDescription : "";
    },

    submitCancel: function () {
      var passengerCancelReason = this.passengerCancelReason.trim();

      if (this.reasonID !== 0) {
        var obj = {
          bookingId: parseInt(this.$route.params.bookinID),
          cancelReasonId: this.reasonID,
          reason: passengerCancelReason,
          modifiedBy: 1,
        };

        BookingService.deleteBooking(obj).then(
          (response) => {
            // console.log(response);
            $("#cancelBookingReason").modal("hide");
            if (response.data) {
              VueSweetalert2.fire({
                toast: false,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                title: "Booking cancelled successfully.",
                icon: "success",
              });

              setTimeout(() => {
                window.location.href = "/travel/";
              }, 1500);
            }
          },
          (error) => {
            // console.log(error);
          }
        );
      } else if (this.reasonID !== 0 && passengerCancelReason.length > 0) {
        var obj = {
          bookingId: parseInt(this.$route.params.bookinID),
          cancelReasonId: this.reasonID,
          reason: passengerCancelReason,
          modifiedBy: 0,
        };
        BookingService.deleteBooking(obj).then(
          (response) => {
            // console.log(response);
            $("#cancelBookingReason").modal("hide");
            if (response.data) {
              VueSweetalert2.fire({
                toast: false,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                title: "Booking cancelled successfully.",
                icon: "success",
              });
              setTimeout(() => {
                window.location.href = "/";
              }, 1500);
            }
          },
          (error) => {
            // console.log(error);
          }
        );
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: "Please choose a field.",
        });
      }
    },
  },
};
</script>

<style scoped>
.btn-close {
  border-radius: 100%;
}

.modal-content {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 10px 8px #e5e5e5;
}

.btnSubmit {
  width: 430px;
  height: 38px;
  background: #000080;
  color: #ffffff;
  border-radius: 10px;
}

.modal-footer {
  border-top: none;
}
</style>
